.payment {
  background-color: white;
}
.payment__container {
  margin: 0 10vh 20vh 10vh;
  background-color: white;
}

.checkout__items {
  text-align: center;
  padding: 10px;
  background-color: rgb(234, 237, 237);
  border-bottom: 1px solid lightgray;
}

.checkout__items a {
  text-decoration: none;
}

.payment__section {
  display: flex;
  padding: 20px;
  margin: 0 20px;
  border-bottom: 1px solid lightgray;
}

.payment__title {
  flex: 0.2;
}

.payment__address,
.payment__items,
.payment__details {
  flex: 0.8;
  padding-left: 20px;
}

#button {
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  display: inline-flex;
  width: 20%;
  position: relative;
  max-width: 250px;
  min-width: 100px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

#button :disabled,
#button[disabled] {
  display: inline-flex;
  max-width: 250px;
  min-width: 100px;
  position: relative;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 1px solid;
  margin-top: 10px;
  align-items: center;
  color: rgba(0, 0, 0, 0.26);
  justify-content: center;
}

.payment__priceContainer {
  margin-left: 1em;
}

.currency__output {
  margin-top: 5px;
}

.checkout {
  display: flex;
  padding: 20px;
  background-color: white;
  height: max-content;
}

.checkout__title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.checkout__left {
  margin-right: 17px;
  margin-bottom: 10px;
}

.checkout__ad {
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
}

.checkout_right_banner {
  width: 100%;
  max-width: 343px;
  margin-top: 20px;
  margin-bottom: 20px;
}

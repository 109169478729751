.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
}

#home__row {
  display: flex;
  position: relative;
  z-index: 50;
  margin-left: 5px;
  margin-right: 5px;
}

.amazon__image {
  display: inline-block;
  position: relative;
  margin-top: -100vh;
  margin-bottom: 30px;
  width: 100%;
  height: 420px;
  object-fit: contain;
  z-index: 3;
}

.home__image {
  width: 100%;
  z-index: -2;
  margin-top: -150px;
  margin-bottom: -450px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

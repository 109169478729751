.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.login__container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}

.login__container h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login__container form h5 {
  margin-bottom: 5px;
}

.login__container form input {
  height: 30px;
  border-radius: 2px;
  border: 1px solid;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login__container p {
  margin-top: 15px;
  font-size: 11px;
}

.login__p {
  text-align: center;
}

.login__logo {
  margin: 20px auto 20px auto;
  object-fit: contain;
  width: 100px;
}

#login__signInButton {
  background: #f0c14b;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

#login__registerButton {
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: darkgray;
  color: #111;
}

.checkoutProduct {
  display: flex;
  margin-top: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.checkoutProduct__info {
  padding-left: 30px;
}

#checkoutProduct__button {
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.checkoutProduct__image {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.checkoutProduct__rating {
  display: flex;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
}

.prime__iconCheckout {
  height: 30px;
}

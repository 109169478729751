.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  z-index: 1;
  margin: 10px;
  padding: 18px;
  width: 96.75%;
  max-height: 400px;
  min-width: 100px;
  border: 1px solid lightgray;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.product img {
  max-height: 180px;
  width: 100%;
  max-width: 400px;
  object-fit: contain;
  margin-bottom: 15px;
  background-color: white;
}

.product__rating {
  display: flex;
}

#product__button {
  display: flex;
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.product__info {
  height: 350px;
  margin-bottom: 15px;
}

.product__price {
  margin-top: 5px;
}

.prime__icon {
  z-index: -1;
  height: 30px;
  max-width: 20px;
  margin-left: 0px;
  position: absolute;
  object-fit: cover;
  object-position: 0% 50%;
  overflow: hidden;
  clip: rect(0px, 75px, 80px, 0px);
}
